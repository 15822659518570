<template>
  <div>
    <!--begin::Row-->
    <!-- <div class="row">
        <div class="col-lg-12"> -->
          <div class="card card-custom card-stretch gutter-b">
                <!--begin::Header-->
                <div class="card-header border-0">
                    <h3 class="card-title font-weight-bolder text-dark">Verifikasi Surveyor</h3>
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body pt-0">
                    <!--begin::Item-->
                    <div class="mb-12">
                        <!--begin::Content-->
                        <div>
                            <div style="overflow-x:auto; overflow-y: hidden;">
                                <table id="example" class="table table-striped table-bordered" style="width:100%">
                                  <thead>
                                      <tr>
                                          <th>No</th>
                                          <th>Nama</th>
                                          <th>Role</th>
                                          <th>Username</th>
                                          <th>Email</th>
                                          <th>Status</th>
                                          <th>Aksi</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr v-for="row in users" :key="row.id">
                                          <td></td>
                                          <td>{{row.name}}</td>
                                          <td>{{row.name_role}}</td>
                                          <td>{{row.username}}</td>
                                          <td>{{row.email}}</td>
                                          <td>{{row.status}}</td>
                                          <td>
                                            <div style="width:150px">
                                              <b-button size="sm" @click="$router.push('/pengaturan/profil-surveyor/' + row.id)" variant="success"><i class="flaticon-eye"></i></b-button>&nbsp;
                                              <b-button @click="deleteuser(row.id)" size="sm" variant="danger"><i class="flaticon2-trash"></i> Hapus</b-button>
                                            </div>
                                              
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                            </div>
                        </div>
                        <!--end::Content-->
                    </div>
                    <!--end::Item-->
                </div>
                <!--end: Card Body-->
            </div>
        </div>
    <!-- </div>
  </div> -->
</template>
<style>
  div.messages {
  /* background-color: lightblue; */
  /* width: 110px; */
  height: 500px;
  overflow: auto;
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from 'axios';
import vSelect from "vue-select";

export default {
  name: "dashboard",
  data() {
      return {
        url: localStorage.getItem('baseapi'),
        token: localStorage.getItem("id_token"),
        users:[],
      }
  },
  components: {
      vSelect
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Verifikasi Surveyor" }]);
    this.getdata()
  },
  methods: {
    getdata(){
      $('#example').DataTable().destroy();
        this.loaddata()
    },
    initDatatable() {
        setTimeout(() => {
        var t =  $('#example').DataTable({
            "pagingType": "full_numbers",
            "language": {
                "info": "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
                "paginate": {
                    "first":      "Awal",
                    "last":       "Akhir",
                    "next":       "Selanjutnya",
                    "previous":   "Sebelumnya"
                },
            },
            order: [[0, 'desc']],
            responsive: true,
            destroy: true,
            retrieve: true,
            autoFill: true,
            colReorder: true,

        });
        t.on( 'order.dt search.dt', function () {
        t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
            cell.innerHTML = i+1;
        } );
        } ).draw();
        }, 300)
    },
    loaddata(){
            axios.get(this.url+"/users?filter=id_role,like,surveyor_%;status,=,created", {
              headers: {
                  'xth': this.token
              }
            })
            .then(response => {
                this.users = response.data.data;
                this.initDatatable()
            }).catch(error=>{
                this.$bvToast.toast(error.response.data.message, {
                  title: `Failed `,
                  variant: `danger`,
                  solid: true
                })
                return error
                // this.loaddata()
                });           
    },
    deleteuser(id){
        axios
            .delete(
            this.url+`/users/${id}`,
            {
                headers: {
                "Content-type": "application/json",
                "xth": this.token,
                }
            }
            )
            .then((res) => {
                this.$bvToast.toast('Berhasil Dihapus', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                this.getdata()
            return res;
            })
            .catch((err) => {
            this.$bvToast.toast('Gagal Dihapus', {
              title: `Failed `,
              variant: `danger`,
              solid: true
            })
            return err;
        });
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  },
  created(){
    
  }
};
</script>
